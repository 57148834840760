<script lang="ts" setup>
import { ClipboardIcon } from '@heroicons/vue/24/outline';
import { useCopyPaste } from '~/composables/shared/useCopyPaste';
import ActionButton from '~/components/pro/molecules/ActionButton.vue';

const { t } = useI18n();
const { add } = useToast();
const { readClipboard } = useCopyPaste('');

interface Props {
  position?: 'start' | 'end';
  tooltip?: string
  icon?: string;
}

withDefaults(defineProps<Props>(), {
  position: 'start'
});

const stringRef = defineModel();

async function pasteFromClipboard () {
  try {
    const clipboardContent = await readClipboard();
    const newValue = clipboardContent;
    stringRef.value = newValue;
  } catch (error) {
    add({
      title: t('generics.copy-to-clipboard.error.title'),
      description: t('generics.copy-to-clipboard.paste.error.description'),
      color: 'red',
      icon: 'i-heroicons-information-circle',
      closeButton: {
        icon: ''
      }
    });
    return false;
  }
}
</script>

<template>
  <ActionButton
    :icon="icon||'i-heroicons-clipboard-document-list'"
    :leading="position === 'start'"
    :trailing="position === 'end'"
    class="text-gray-secondary"
    @click="pasteFromClipboard"
  >
    <UTooltip v-if="!!tooltip" :text="tooltip!">
      <slot>
        {{ $t('generics.paste-from-clipboard') }}
      </slot>
    </UTooltip>
    <template v-else>
      <slot>
        {{ $t('generics.paste-from-clipboard') }}
      </slot>
    </template>

    <template #leadingIcon>
      <ClipboardIcon />
    </template>
  </ActionButton>
</template>
